/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

import { useIdentityContext } from "../../../services/react-netlify-identity-gotrue"
import { useSubInfoContext } from "../../../services/subinfocontext"

import PlaySVG from "../../../scss/img/icon/sibsvg/sib-icon-theatre.svg"

const SIBContactForm = (props) => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" })

  const [formError, setFormError] = useState(false)
  const [queryType, setQueryType] = useState("general")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [wasSubmit, setWasSubmit] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isSchoolQuery, setIsSchoolQuery] = useState(false)

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const netlifySubmit = (data) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "sib-contact-us-form",
        ...data,
      }),
    })
      .then(() => {
        setWasSubmit(true)
        setIsSubmitting(false)
      })
      .catch((error) => {
        setFormError(error)
        setIsSubmitting(false)
      })
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    data["query"] = queryType
    netlifySubmit(data)
  }

  const selectQueryType = (e) => {
    setQueryType(e.target.value)
    if (e.target.value === "subinfo") {
      setIsSchoolQuery(true)
    } else {
      setIsSchoolQuery(false)
    }
  }

  const numStudentsValidate = () => {
    if (!isSchoolQuery) {
      return true;
    }
    let num_students = getValues("num_students")
    if (isNaN(num_students)) {
      return "nan"
    }
    if (num_students < 31) {
      return "too_few"
    }
    return true;
  }

  useEffect(() => {
    if (props.fromPricing) {
      setQueryType("subinfo")
      setIsSchoolQuery(true)
    }
  }, [props.fromPricing])

  useEffect(() => {
    if (
      identity &&
      subInfoContext.subInfo &&
      !subInfoContext.subInfo.isStudentCode
    ) {
      setIsLoggedIn(true)
      setValue("email", subInfoContext.subInfo.email, { shouldValidate: false })
      setValue("full_name", subInfoContext.subInfo.metadata.full_name, {
        shouldValidate: false,
      })
      setValue("school_name", subInfoContext.subInfo.metadata.school_name, {
        shouldValidate: false,
      })
    }
  }, [subInfoContext])

  return (
    <>
      <div className={`${wasSubmit ? "" : "is-hidden"}`}>
        <div className="container has-text-centered pt-6">
          <p>Thank you for your message!</p>
          <p>
            <br />
          </p>
          <p>
            The Shakespeare In Bits team will review your query and contact you
            as soon as possible.
          </p>
          <div className="block py-5 has-text-sibredorange">
            <Link className="button is-black" to="/">
              Home
            </Link>
          </div>
        </div>
      </div>
      {formError && (
        <div className="notification-error-text py-1">{formError}</div>
      )}
      <div className={`${isSubmitting || wasSubmit ? "is-hidden" : ""}`}>
        <form
          name="sib-contact-us-form"
          onSubmit={handleSubmit(onSubmit)}
          data-netlify="true"
        >
          <input type="hidden" name="sib-contact-us-form" />
          <div className={`${isLoggedIn ? "is-hidden" : ""}`}>
            <div className="pb-1">Your Name</div>
            <div className="field pb-1">
              <p className="control has-icons-left">
                <input
                  {...register("full_name", {
                    required: true,
                    minLength: 6,
                    maxLength: 100,
                  })}
                  className="input"
                  type="text"
                  name="full_name"
                />
                <span className="icon is-small is-left">
                  <PlaySVG fill="#c0c0c0" />
                </span>
                <span className="notification-error-text">
                  {errors.full_name && errors.full_name.type === "required" && (
                    <span>Full Name is required</span>
                  )}
                  {errors.full_name &&
                    errors.full_name.type === "minLength" && (
                      <span>Minimum 6 Characters</span>
                    )}
                  {errors.full_name &&
                    errors.full_name.type === "maxLength" && (
                      <span>Max Length Exceeded (100)</span>
                    )}
                </span>
              </p>
            </div>
          </div>
          <div className={`${isLoggedIn ? "is-hidden" : ""}`}>
            <div className="pb-1">Your Email</div>
            <div className="field pb-1">
              <p className="control has-icons-left">
                <input
                  {...register("email", {
                    required: true,
                    maxLength: 100,
                    minLength: 5,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  className="input"
                  type="text"
                  name="email"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
                <span className="notification-error-text">
                  {errors.email && errors.email.type === "required" && (
                    <span>Email is required</span>
                  )}
                  {errors.email && errors.email.type === "maxLength" && (
                    <span>Max Length Exceeded (100)</span>
                  )}
                  {errors.email && errors.email.type === "minLength" && (
                    <span>Minimum 5 Characters</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span>Please enter a valid Email address</span>
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className={`${isLoggedIn ? "is-hidden" : ""}`}>
            <div className="pb-1">School / Institution Name (Optional)</div>
            <div className="field pb-1">
              <p className="control">
                <input
                  {...register("school_name", {
                    required: false,
                    minLength: 6,
                    maxLength: 100,
                  })}
                  className="input"
                  type="text"
                  name="school_name"
                />
                <span className="notification-error-text">
                  {errors.school_name &&
                    errors.school_name.type === "maxLength" && (
                      <span>Max Length Exceeded (100)</span>
                    )}
                  {errors.school_name &&
                    errors.school_name.type === "minLength" && (
                      <span>Minimum 6 Characters</span>
                    )}
                </span>
              </p>
            </div>
          </div>
          <div className="pb-1">Nature of Query</div>
          <div className="field pb-1">
            <div className="select is-sibblack">
              <select
                name="query"
                onChange={(e) => selectQueryType(e)}
                onBlur={(e) => selectQueryType(e)}
                defaultValue={props.fromPricing ? "subinfo" : "general"}
              >
                <option value="general">I have a general query</option>
                <option value="subinfo">
                  Get a Quote
                </option>
                <option value="support">Something isn't working</option>
                <option value="error">
                  I believe I've found an error in or have an issue with the
                  product's content
                </option>
                <option value="feedback">
                  I have some feedback about the product
                </option>
              </select>
            </div>
          </div>
          <div className={`${isSchoolQuery ? "" : "is-hidden"}`}>
            <div className="pb-1">
              Approximately how many students will be using Shakespeare In Bits?
            </div>
            <div className="field pb-1">
              <p className="control">
                <input
                  {...register("num_students", {
                    validate: numStudentsValidate,
                    valueAsNumber: true,
                  })}
                  className="input"
                  type="text"
                  name="num_students"
                />
                <span className="notification-error-text">
                  {errors.num_students && errors.num_students.message === "too_few" && (
                    <span>
                      Minimum 31 - See our{" "}
                      <Link to="/pricing">Class Account</Link> for 30 or fewer
                      students.
                    </span>
                  )}
                  {errors.num_students &&
                    errors.num_students.message === "required" && (
                      <span>Number of students is required</span>
                    )}
                  {errors.num_students &&
                    errors.num_students.message === "nan" && (
                      <span>Please enter a number</span>
                    )}
                 </span>
              </p>
            </div>
          </div>
          <div className="pb-1">Your Message</div>
          <div className="pb-1 is-size-7">(Max 2000 Characters)</div>
          <div className="field pb-1">
            <textarea
              {...register("message", {
                required: true,
                maxLength: 2000,
                minLength: 20,
              })}
              className="input"
              type="text"
              name="message"
              rows="10"
              style={{ height: "auto" }}
            />
            <span className="notification-error-text">
              {errors.message && errors.message.type === "required" && (
                <span>Message is required</span>
              )}
              {errors.message && errors.message.type === "maxLength" && (
                <span>Max Length Exceeded (2000)</span>
              )}
              {errors.message && errors.message.type === "minLength" && (
                <span>Minimum 20 Characters</span>
              )}
            </span>
          </div>
          <button
            className="button is-black"
            type="submit"
            id="login-user-submit-button"
          >
            Submit Query
          </button>
        </form>
      </div>
    </>
  )
}

export default SIBContactForm
