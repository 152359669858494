import React from "react"

import Layout from "../components/common/layout/layout"
import { Link } from "gatsby"

import SIBContactForm from "../components/frontend/contact/contactform"

const ContactPage = ({ location }) => {
  let fromPricing = location.state && location.state.fromPricing && location.state.fromPricing ? true : false
  return (
    <Layout showFrontendLinks={true}>
      <SIBContactHeader />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            <SIBContactForm fromPricing={fromPricing} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const SIBContactHeader = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1 className="title">Contact Us!</h1>
              <p className="title is-6">
                We'd love to hear from you about any questions, concerns or
                feedback. Please use the contact form below to get in touch with
                us.
              </p>
              <p className="title is-6">
                You can also check if your question is answered in our
                <br />
                <Link className="has-text-sibredorange" to="/faq">
                  Frequently Asked Questions
                </Link>
              </p>
              <p className="title is-6">
                Alternatively, you can always email us directly at
                <br />
                <span className="has-text-sibredorange">
                  <a href="mailto:contact@shakespeareinbits.com" className="has-text-sibredorange">
                    contact@shakespeareinbits.com
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactPage
